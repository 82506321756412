// == PWD WP Sass Framework ==
// Theme footer styles
// Template: footer.php

@import "common/variables";
@import "common/tools";

.footer-theme {
	border-top: 1px solid $theme-color-5;
	background-color: rgba($theme-color-5, .25);
}

.footer__content {
	font-weight: 400;
	@include pwd-fontsize(16);
	color: $theme-color-1;

	ul {
		margin-left: 0;
		padding-left: 0;
		list-style: none;
	}

	li {
		position: relative;
		padding-left: .6em;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: .6em;
			height: 1em;
			content: "›";
			speak: none;
		}
	}

	h2 {
		@include pwd-fontsize(18);
		text-transform: uppercase;
		color: $theme-color-1;
		font-family: $font-family-2;
		font-weight: bold;
	}

	a,
	li:before {
		color: $theme-color-1;
	}

	a {
		font-weight: inherit;

		&:hover {
			//			text-decoration-color: #ffffff;
		}

		&:focus {
			//			outline-color: #ffffff;
		}
	}
}

.footer__signature,
.footer__signature a {
	color: $base-signature-text-color;
}

.footer__signature {
	padding: $base-margin * 0.5;
	text-align: center;
}

@include media-breakpoint-up(lg) {
	.flex-lg {
		display: flex;

		&--justify-end {
			justify-content: flex-end;
		}

		&--align-self-start {
			align-self: flex-start;
		}
	}
}
